/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { LocationUI } from "./presentation/Location";

// TODO: translate this - instructions at https://react.i18next.com/latest/using-with-hooks#configure-i18next don't work
const unassignedMachines = `Unassigned Machines`;

const unassignedMachinesLocation: LocationUI = {
  id: "",
  parentId: undefined,
  name: unassignedMachines,
  children: [],
  ancestors: [],
  trail: unassignedMachines,
  descendants: [],
  timeZone: "UTC",
};
export default {
  unassignedMachinesLocation,
  authLocalStorageKey: "renishaw-central-auth",
  clientId: "1a1b72f2-5d62-44cb-a9d7-e8fc1ff3d119",
  serverLicenceRemainingDays: 30,
  sample: {
    Default: "default",
    Hour: "hour",
    Day: "day",
    Week: "week",
    Month: "month",
    Raw: "raw",
  },
  emptyState: "--",
  processActionsOffsetAdjustmentOffsetTypes: [
    "offset",
    "ipcoffsetadjustmentupdate",
    "offsetadjustmentapplied",
    "offsetadjustmentcalculation",
  ],
  offsetErrorMin: 99,
  offsetWarningMin: 80,
  offsetWarningMax: 99,
};

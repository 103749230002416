/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
/* eslint-disable camelcase */
// Important - all imports are importing the same instance of axios and not a new instance each time.
// instance gets mutated with setBase in the app start-up.

import axios, { AxiosRequestConfig } from "axios";
import { store, rootActions } from "../store";
import { CMSClient } from "@/cms-api/";
import constants from "@/constants";
import { getAuthentication } from "@/store/sagas/authentication";
import { AuthResponse, isAuthFailure } from "@centralwebteam/narwhal";

const instance = axios.create();
let refreshing: Promise<AuthResponse> | null = null;
let tokenRetries = 0;
let isFailedEndpoints: boolean = false;
let timerId: NodeJS.Timeout | null = null;

const waitForAuth = async (config: AxiosRequestConfig<any>) => {
  if (refreshing === null) {
    const auth = getAuthentication();
    if (!auth) {
      store.dispatch(
        rootActions.session.axiosInterceptorFailedToRefreshToken()
      );
      return Promise.reject(
        "Failed to refresh auth token. No refresh token found in local storage."
      );
    }
    if (!localStorage.getItem("refreshing")) {
      localStorage.setItem("refreshing", JSON.stringify(true));
      refreshing = CMSClient.auth.refresh({
        grant_type: "refresh_token",
        client_id: constants.clientId,
        refresh_token: auth?.refresh_token,
      }).promise;

      refreshing.then(
        (refreshResolve) => {
          if (isAuthFailure(refreshResolve)) {
            console.warn(`Auth failure: ${refreshResolve.error}`);
          } else {
            tokenRetries = 0;
            store.dispatch(
              rootActions.session.userSessionRefreshed(refreshResolve)
            );
          }
          refreshing = null;
          // Resend the original call with new auth
          return Promise.resolve(instance.request(config));
        },
        (refreshReject) => {
          refreshing = null;
          console.warn(`Failed to refresh auth token. ${refreshReject}`);
          Promise.reject(
            "Failed to refresh auth token. No valid token received from the server."
          );
        }
      );
    }
  } else {
    await refreshing;
    // Resend the original call with new auth
    return Promise.resolve(instance.request(config));
  }
};
const handleTimer = () => {
  setTimeout(() => {
    if (!isFailedEndpoints) {
      store.dispatch(
        rootActions.session.showFooter({
          showFooter: false,
        })
      );
    }
    timerId = null;
  }, 10000);
};

/** Set up all Axios requests with authentication headers */
instance.interceptors.request.use(async (request) => {
  if (
    localStorage.getItem("refreshing") &&
    request.url &&
    request.url !== "token"
  ) {
    await addNumberOfSecondsDelay(5);
    localStorage.removeItem("refreshing");
  }
  if (request.url && request.url === "token") {
    // This is a token request so remove Authorization header
    delete request.headers?.Authorization;
  } else {
    try {
      let storedAuth = localStorage.getItem(constants.authLocalStorageKey);

      if (!storedAuth || JSON.parse(storedAuth).expiry < Date.now() + 999) {
        if (store.getState().authenticated && !storedAuth) {
          // Warn only if the store says we're logged in, otherwise we get recurring warnings while logged out
          console.warn("Auth token from localStorage is missing or expired");
        }
        const locationPathName =
          localStorage.getItem("location-pathname-search") ?? "";
        if (!locationPathName.includes("/reset-password")) {
          waitForAuth(request).then(
            () => {
              storedAuth = localStorage.getItem(constants.authLocalStorageKey);
            },
            () => {
              /* No need to log this promise rejection */
            }
          );
        }
      }
      if (!storedAuth) {
        // No auth token! This request will probably fail, but try it anyway. A 401 will trigger another auth refresh attempt.
        if (store.getState().authenticated) {
          // Warn only if the store says we're logged in, otherwise we get recurring warnings while logged out
          console.warn("Auth token is still missing after a refresh attempt");
        }
        return request;
      }
      const { token_type, access_token } = JSON.parse(storedAuth);
      request.headers.setAuthorization(`${token_type} ${access_token}`);
    } catch (error) {
      console.warn("Failed to set Authorization header");
    }
  }
  return request;
}, undefined);

/*
 * IF 600
 *  log out
 * IF 401
 *  error
 * IF refreshing
 *  wait for refreshing to complete
 * ELSE
 *  attempt refresh
 * IF refresh succeeds
 *  replace err.config auth header with new auth header
 *  return new request using config
 * ELSE
 *   unauthenticate user
 */

/** Handle all Axios responses */
instance.interceptors.response.use(
  /* Successful responses (2xx) */
  async (r: any) => {
    handleTimer();
    isFailedEndpoints = false;

    if (!r.data) return Promise.resolve(r);
    return Promise.resolve(r.data);
  },
  /* Unsuccessful responses (>=400)*/
  async (r) => {
    const status: number | undefined = r.response?.status;
    if (status === undefined || (status >= 500 && status < 600)) {
      isFailedEndpoints = true;
    }

    if (isFailedEndpoints && timerId === null) {
      store.dispatch(
        rootActions.session.showFooter({
          showFooter: true,
        })
      );
      timerId = setTimeout(handleTimer, 10000);
    }

    try {
      if (status === 600) {
        store.dispatch(rootActions.session.serverNotLicensed());
        return Promise.reject("Server is not licensed");
      }
      // login or refresh call
      else if (r?.config?.url === "token") {
        tokenRetries++;

        if (tokenRetries > 9) {
          store.dispatch(rootActions.session.axiosInterceptorTokenCallFailed());
          // Last-ditch attempt to fix by reloading the whole page
          document.location.reload();
          return Promise.reject(
            "Failed to refresh auth token. Reloading the page."
          );
        }
      }

      // Refresh the session if it's expired. Usually, the authentication saga should refresh it before this point.
      if (status === 401) {
        await waitForAuth(r.config);
      } else {
        // Not a 401, so there's no quick fix
        return Promise.reject(r);
      }
    } catch (error) {
      // @ts-ignore
      if (error?.response?.status === 400) {
        store.dispatch(rootActions.session.userLoggedOut());
        return Promise.reject(r);
      }
    }
  }
);

export function setBase(baseURL: string) {
  instance.defaults.baseURL = baseURL;
}

export default instance;

const addNumberOfSecondsDelay = (n: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, n * 1000);
  });
};

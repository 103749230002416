/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import {
  aliases,
  ToleranceType,
  toleranceTypes,
  stringFunctions,
} from "@centralwebteam/narwhal";

export const toleranceTypeMapper = (
  toleranceType: string | undefined
): ToleranceType => {
  return (
    toleranceTypes.find((t) => stringFunctions.looseMatch(toleranceType, t)) ??
    toleranceTypes.find((a) =>
      aliases[a].some((alias: string) =>
        stringFunctions.looseMatch(toleranceType, alias)
      )
    ) ??
    "Unknown"
  );
};
